import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c('div',{attrs:{"id":"table-actions"}},[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openCreateModal()}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("crear remito")])],1)],1)],1),_c(VCardText,[_c(VRow,[_c(VCol,{staticClass:"d-flex",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"text-center mr-3"},[_c(VSelect,{attrs:{"items":_vm.veterinary_items,"item-value":"id","item-text":"name","dense":"","label":"Filtrar por usuario"},on:{"change":function($event){return _vm.getInvoices()}},model:{value:(_vm.filter.vet),callback:function ($$v) {_vm.$set(_vm.filter, "vet", $$v)},expression:"filter.vet"}})],1),(_vm.filter.vet)?_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.filter.vet = null;
                    _vm.getInvoices();}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)]}}],null,false,1337506644)},[_c('span',[_vm._v("Eliminar filtro")])]):_vm._e()],1),_c(VCol,{staticClass:"d-flex",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"text-center mr-3"},[_c(VSelect,{attrs:{"items":_vm.status_items,"item-value":"id","item-text":"name","dense":"","label":"Filtrar por estado"},on:{"change":function($event){return _vm.getInvoices()}},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}})],1),(_vm.filter.status)?_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.filter.status = null;
                    _vm.getInvoices();}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)]}}],null,false,1014493287)},[_c('span',[_vm._v("Eliminar filtro")])]):_vm._e()],1)],1)],1)],1),_c(VDataTable,{attrs:{"id":"mi-tabla","headers":_vm.tableColumns,"items":_vm.invoicesListTable,"page":_vm.input_search && _vm.input_search.length ? _vm.current_page_with_search : _vm.current_page,"server-items-length":_vm.invoicesListTableCount,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [10, 20, 50],
        'items-per-page-text': 'Items por página',
      }},on:{"update:page":function($event){return _vm.changingInvoicePage($event)},"update:items-per-page":function($event){return _vm.changingItemsPerPage($event)}},scopedSlots:_vm._u([{key:"item.request",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"date-container"},[_vm._v(_vm._s(item.client["sku"]))])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c(VChip,{staticClass:"v-chip-light-bg",class:item.status && item.status.id === 0
              ? 'primary--text'
              : item.status && item.status.id === 1
              ? 'success--text'
              : 'error--text',attrs:{"small":"","color":item.status && item.status.id === 0
              ? 'primary'
              : item.status && item.status.id === 1
              ? 'success'
              : 'error'}},[_vm._v(" "+_vm._s(item.status.status)+" ")])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openViewInvoiceModal(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Remito")])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","disabled":item.status.id == 1},on:{"click":function($event){return _vm.openEditInvoiceModal(item.id)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar Remito")])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","disabled":item.status.id == 1},on:{"click":function($event){return _vm.openRegisterPaymentModal(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"info"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircleOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Registrar pago")])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","disabled":item.client.type === 'request'},on:{"click":function($event){return _vm.openDeleteModal(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar Remito")])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(item.status.id === 0)?_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","disabled":!item.budget && !item.budget.length},on:{"click":function($event){return _vm.downloadPdf(item.budget)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownloadOutline)+" ")])],1):_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","disabled":!item.payment && !item.payment.proof_of_payment.length},on:{"click":function($event){return _vm.downloadPdf(item.payment.proof_of_payment)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownloadOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.status.id === 0 ? "Descargar Remito" : "Descargar comprobante de pago"))])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.sharePDF(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"info"}},[_vm._v(" "+_vm._s(_vm.icons.mdiShareVariantOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Compartir "+_vm._s(item.status.id === 0 ? "remito" : "comprobante de pago"))])])]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.input_search.length ? "No hay remitos que coincidan con la búsqueda" : "No hay remitos registrados aún")+" ")]},proxy:true}],null,true)}),_c(VDialog,{attrs:{"width":"auto","max-width":"800px"},model:{value:(_vm.delete_invoice_modal),callback:function ($$v) {_vm.delete_invoice_modal=$$v},expression:"delete_invoice_modal"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" ¿Eliminar el remito asociado a la solicitud: "+_vm._s(_vm.invoice_to_delete.request_sku)+" ? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.delete_invoice_modal = !_vm.delete_invoice_modal}}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"success"},on:{"click":function($event){return _vm.deleteInvoice()}}},[_vm._v(" Eliminar ")]),_c(VSpacer)],1)],1)],1)],1),_c('InvoiceEditModal',{ref:"invoice_edit_modal",on:{"show-alert":_vm.showAlert,"refresh-list":function($event){return _vm.getInvoices()}}}),_c('RegisterPaymentModal',{ref:"register_payment_modal",on:{"refresh-list":function($event){return _vm.getInvoices()},"show-alert":_vm.showAlert}}),_c('AlertPopUp',{ref:"alert_pop_up"}),_c('ShareModal',{ref:"share_modal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }