<template>
  <div>
    <v-card>
      <v-card-title>Presupuestos</v-card-title>
      <InvoicesList />
    </v-card>
  </div>
</template>

<script>
import InvoicesList from '@/components/invoices/InvoicesList';
  export default {
    components:{
      InvoicesList
    }
    
  }
</script>