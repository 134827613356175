<template>
  <div>
    <v-card>
      <div id="table-actions">
        <v-divider class="mt-4"></v-divider>
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <!-- Search -->
          <!-- <v-text-field
            :append-icon="icons.mdiMagnify"
            v-model="input_search"
            placeholder="Buscar remito"
            outlined
            hide-details
            dense
            class="me-3 mb-4"
          /> -->

          <v-spacer></v-spacer>

          <div class="d-flex align-center flex-wrap">
            <v-btn color="primary" class="mb-4 me-3" @click="openCreateModal()">
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>crear remito</span>
            </v-btn>
          </div>
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col cols="6" sm="3" class="d-flex">
              <div class="text-center mr-3">
                <v-select
                  :items="veterinary_items"
                  item-value="id"
                  item-text="name"
                  @change="getInvoices()"
                  dense
                  label="Filtrar por usuario"
                  v-model="filter.vet"
                ></v-select>
              </div>

              <v-tooltip right v-if="filter.vet">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="
                      filter.vet = null;
                      getInvoices();
                    "
                  >
                    <v-icon color="error">
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Eliminar filtro</span>
              </v-tooltip>
            </v-col>

            <v-col cols="6" sm="3" class="d-flex">
              <div class="text-center mr-3">
                <v-select
                  :items="status_items"
                  item-value="id"
                  item-text="name"
                  @change="getInvoices()"
                  dense
                  label="Filtrar por estado"
                  v-model="filter.status"
                ></v-select>
              </div>

              <v-tooltip right v-if="filter.status">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="
                      filter.status = null;
                      getInvoices();
                    "
                  >
                    <v-icon color="error">
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Eliminar filtro</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <v-data-table
        id="mi-tabla"
        :headers="tableColumns"
        :items="invoicesListTable"
        :page="
          input_search && input_search.length ? current_page_with_search : current_page
        "
        :server-items-length="invoicesListTableCount"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 20, 50],
          'items-per-page-text': 'Items por página',
        }"
        @update:page="changingInvoicePage($event)"
        @update:items-per-page="changingItemsPerPage($event)"
      >
        <!-- <template #[`item.sku`]="{item}">
          <span class="sku-container">{{item.sku}}</span>
        </template> -->

        <template #[`item.request`]="{ item }">
          <span class="date-container">{{ item.client["sku"] }}</span>
        </template>

        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="
              item.status && item.status.id === 0
                ? 'primary'
                : item.status && item.status.id === 1
                ? 'success'
                : 'error'
            "
            :class="
              item.status && item.status.id === 0
                ? 'primary--text'
                : item.status && item.status.id === 1
                ? 'success--text'
                : 'error--text'
            "
            class="v-chip-light-bg"
          >
            {{ item.status.status }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon @click="openViewInvoiceModal(item)">
                <v-icon color="secondary">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Ver Remito</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                :disabled="item.status.id == 1"
                @click="openEditInvoiceModal(item.id)"
              >
                <v-icon color="primary">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Editar Remito</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                :disabled="item.status.id == 1"
                @click="openRegisterPaymentModal(item)"
              >
                <v-icon color="info">
                  {{ icons.mdiCheckCircleOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Registrar pago</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="openDeleteModal(item)"
                :disabled="item.client.type === 'request'"
              >
                <!-- :disabled="item.status.id == 1" -->
                <v-icon color="error">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Eliminar Remito</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.status.id === 0"
                v-bind="attrs"
                v-on="on"
                icon
                :disabled="!item.budget && !item.budget.length"
                @click="downloadPdf(item.budget)"
              >
                <v-icon color="primary">
                  {{ icons.mdiDownloadOutline }}
                </v-icon>
              </v-btn>

              <v-btn
                v-else
                v-bind="attrs"
                v-on="on"
                icon
                :disabled="!item.payment && !item.payment.proof_of_payment.length"
                @click="downloadPdf(item.payment.proof_of_payment)"
              >
                <v-icon color="success">
                  {{ icons.mdiDownloadOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{
              item.status.id === 0 ? "Descargar Remito" : "Descargar comprobante de pago"
            }}</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon @click="sharePDF(item)">
                <v-icon color="info">
                  {{ icons.mdiShareVariantOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span
              >Compartir
              {{ item.status.id === 0 ? "remito" : "comprobante de pago" }}</span
            >
          </v-tooltip>
        </template>

        <!-- no-data -->
        <template v-slot:no-data>
          {{
            input_search.length
              ? "No hay remitos que coincidan con la búsqueda"
              : "No hay remitos registrados aún"
          }}
        </template>
      </v-data-table>

      <!-- DELETE invoice modal -->
      <v-dialog v-model="delete_invoice_modal" width="auto" max-width="800px">
        <v-card>
          <v-card-title>
            ¿Eliminar el remito asociado a la solicitud:
            {{ invoice_to_delete.request_sku }} ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="delete_invoice_modal = !delete_invoice_modal"
            >
              Cancelar
            </v-btn>
            <v-btn color="success" @click="deleteInvoice()"> Eliminar </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <InvoiceEditModal
      ref="invoice_edit_modal"
      @show-alert="showAlert"
      @refresh-list="getInvoices()"
    />
    <RegisterPaymentModal
      ref="register_payment_modal"
      @refresh-list="getInvoices()"
      @show-alert="showAlert"
    />
    <AlertPopUp ref="alert_pop_up" />

    <ShareModal ref="share_modal" />
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import debounce from "../../plugins/debounce.js";
import InvoiceEditModal from "@/components/invoices/InvoiceEditModal";
import RegisterPaymentModal from "@/components/invoices/RegisterPaymentModal";
import AlertPopUp from "@/components/AlertPopUp.vue";
import ShareModal from "@/components/share-modal/ShareModal.vue";
import {
  mdiEyeOutline,
  mdiPencilOutline,
  mdiClose,
  mdiCheckCircleOutline,
  mdiPlus,
  mdiDeleteOutline,
  mdiMagnify,
  mdiDownloadOutline,
  mdiShareVariantOutline,
} from "@mdi/js";

export default {
  data: () => {
    return {
      current_page: 1,
      current_page_with_search: 1,
      page_size: 10,

      input_search: "",
      delete_invoice_modal: false,
      invoice_to_delete: {},

      filter: { vet: null, status: null },
      veterinary_items: [],
      status_items: [
        {
          name: "Pendiente",
          id: "0",
        },
        {
          name: "Pagado",
          id: "1",
        },
      ],
    };
  },
  methods: {
    async sharePDF(item) {
      if (item.client?.type === "request") {
        try {
          const contacts = await this.getContacts(item.client.id);
          const PAID = 1;
          const requestless = false;


          this.$refs.share_modal.openSendModal(requestless, contacts, {
            id: item.status.id == PAID ? item?.payment.id : item.id,
            type: item.status.id == PAID ? "payment" : "invoice",
          });
        } catch (error) {
          console.log(error);
        }
      } else {

        const contacts = { email: item.client.email, name: item.client.name, id: item.client.id ?? null, type: item.client.type };
        const PAID = 1;
        const requestless = true;

        this.$refs.share_modal.openSendModal(requestless, contacts, {
          id: item.status.id == PAID ? item?.payment.id : item.id,
          type: item.status.id == PAID ? "payment" : "invoice",
        });
      }
    },

    downloadPdf(url) {
      window.open(url, "_blank");
    },

    async getContacts(request_id) {
      const { data } = await this.callGetOneRequest(request_id);
      const contacts = [];
      const contacts_container_raw = [data.userdata, data.veterinary_clinic, data.tutor];

      contacts_container_raw.forEach((contact, idx) => {
        if (contact && Object.keys(contact).length) {
          contacts.push({
            id: contact.id,
            label: idx === 0 ? "Veterinario" : idx === 1 ? "Clínica" : "Tutor",
            name: contact.name,
            email: contact.email,
            phone: contact.phone,
          });
        }
      });

      return contacts;
    },

    async callGetOneRequest(id) {
      try {
        const response = await this.$api.getRequest(id);
        return response;
      } catch (e) {
        console.log(e);
        return false;
      }
    },

    async getInvoices() {
      this.loading = true;
      try {
        const response = await this.$api.getInvoices(this.getQueryParams);
        this.invoicesListTable = response.results;
        this.invoicesListTableCount = response.count;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    async getVeterinaryUsers() {
      try {
        const response = await this.$api.getUsersList({ role: 0, summary: true });
        this.veterinary_items = response;
      } catch (error) {
        console.log(error);
      }
    },

    openDeleteModal(item) {
      this.invoice_to_delete = {
        id: item.id,
        request_sku: item.request?.sku,
      };

      this.delete_invoice_modal = !this.delete_invoice_modal;
    },

    showAlert(data) {
      this.$refs.alert_pop_up.displayAlert({ message: data.message, type: data.type });
    },

    async deleteInvoice() {
      try {
        await this.$api.deleteInvoice(this.invoice_to_delete.id);
        this.invoice_to_delete = {};
        this.delete_invoice_modal = !this.delete_invoice_modal;
        this.getInvoices();
      } catch (error) {
        console.log(error);
      }
    },

    openEditInvoiceModal(id) {
      this.$refs.invoice_edit_modal.openModal(id);
    },

    openCreateModal() {
      this.$refs.invoice_edit_modal.openModal();
    },

    openRegisterPaymentModal(invoice) {
      this.$refs.register_payment_modal.openModal({
        invoice_id: invoice.id,
        client_id: invoice.client?.id ? invoice.client?.id : null,
        type: invoice.client.type,
        client_name: invoice.client?.name ? invoice.client?.name : null,
      });
    },

    openViewInvoiceModal(invoice) {
      this.$refs.register_payment_modal.openModal({
        invoice_id: invoice.id,
        client_id: invoice.client?.id ? invoice.client?.id : null,
        type: invoice.client.type,
        client_name: invoice.client?.name ? invoice.client?.name : null,
        view_mode: true,
      });
    },

    changingInvoicePage(e) {
      this.current_page = e
      this.getInvoices();
    },

    changingItemsPerPage(e) {
      this.page_size = e
      this.getInvoices();
    },

    // deprecated search
    // debouncedSearch: debounce(function () {
    //   this.getInvoices();
    // }, 400),
  },
  mounted() {
    this.getInvoices();
    this.getVeterinaryUsers();
  },
  components: {
    InvoiceEditModal,
    RegisterPaymentModal,
    AlertPopUp,
    ShareModal,
  },

  computed: {
    getQueryParams() {
      const filter = {
        page_size: this.page_size,
        page: this.current_page,
      };

      if (this.input_search.length) {
        filter["search"] = this.input_search;
        filter["page"] = this.current_page_with_search;
      }
      if (this.filter.status) filter["status"] = this.filter.status;
      if (this.filter.vet) filter["userdata"] = this.filter.vet;

      return filter;
    },
  },

  watch: {
    // deprecated search
    // input_search(newValue, oldValue) {
    //   if (!oldValue) {
    //     this.current_page_with_search = 1;
    //   }
    //   this.debouncedSearch();
    // },
  },

  setup() {
    const invoicesListTableCount = ref(0);
    const invoicesListTable = ref([]);

    const tableColumns = [
      // { text: 'SKU', value: 'sku', align: 'center', sortable: false },
      { text: "SOLICITUD", value: "request", align: "left", sortable: false },
      { text: "ESTADO", value: "status", align: "center", sortable: false },
      { text: "- ACCIONES -", value: "actions", align: "center", sortable: false },
    ];

    const loading = ref(false);

    return {
      loading,
      invoicesListTableCount,
      invoicesListTable,
      tableColumns,
      icons: {
        mdiEyeOutline,
        mdiPencilOutline,
        mdiClose,
        mdiCheckCircleOutline,
        mdiMagnify,
        mdiPlus,
        mdiDeleteOutline,
        mdiDownloadOutline,
        mdiShareVariantOutline,
      },
    };
  },
};
</script>

<style>
.date-container {
  font-weight: 500;
  color: rgb(125, 125, 125);
}

@media print {
  .v-application {
    background: none !important;
  }

  /* hide page navs & footers */
  .app-navigation-menu,
  .v-app-bar,
  .v-footer {
    display: none !important;
  }

  /* hidding elements from this component */
  #table-actions {
    display: none !important;
  }

  /* set no paddings.. can u adjust on print interface */
  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  /* // make flat cards for print */
  .v-card {
    padding-top: 20px !important;
    box-shadow: none !important;
  }
}
</style>
